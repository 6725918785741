import imagesLoaded from "imagesloaded/imagesloaded.pkgd.min"
import Masonry from "masonry-layout/dist/masonry.pkgd.min";

const initMasonry = () => {
  const gridEl = document.querySelector(".masonry-grid");

  if(gridEl) {
    let masonry = new Masonry( gridEl, {
      columnWidth: '.masonry-sizer',
      itemSelector: '.masonry-item',
      percentPosition: true,
      gutter: 10,
      horizontalOrder: true
    });
  
    // Refresh the Masonry grid as each image loads in
    var imgLoad = imagesLoaded( gridEl);
    imgLoad.on( 'progress', function() {
      masonry.layout();
    });

    // Refresh the grid when toggling to be visible on index pages
    var galleryViewButton = document.getElementById("galleryViewButton");
    if (galleryViewButton) {
      document.getElementById("galleryViewButton").addEventListener("click", function() {
        masonry.layout();
      }.bind(this));
    }
  }
  
}

export { initMasonry };