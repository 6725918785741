import Swiper, { Navigation, Scrollbar } from 'swiper';

const initSwiper = () => {
  const swiperEl = document.querySelector(".swiper");

  if (swiperEl) {
    var spaceBetween = ((swiperEl.hasAttribute('data-space-between')) ? parseInt(swiperEl.dataset.spaceBetween) : 16);
    let swiper = new Swiper('.swiper', {
      modules: [Navigation, Scrollbar],
      slidesPerView: 'auto',
      direction: 'horizontal',
      spaceBetween: spaceBetween,    
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        hide: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const slideCountEl = document.querySelector(".swiper-slide-count");
    if(slideCountEl) {
      slideCountEl.textContent = `1 / ${swiper.slides.length}`;
      swiper.on('transitionEnd', function() {
        slideCountEl.textContent = `${swiper.realIndex + 1} / ${swiper.slides.length}`;
      });
    }

  }
}

export { initSwiper };